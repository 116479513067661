<template>
  <section ref="main">
    <section class="header" id="s1">
      <section :class="`nav ${data.className}`">
        <div :class="`nav_container ${language ? '' : 'en'}`">
          <img class="nav_logo" src="@/assets/logo.svg" alt="" />
          <ul>
            <li :class="S1" @click="handleNav('s1')">
              <a href="#s1">{{ $t("nav.index") }}</a>
              <div></div>
            </li>
            <li :class="S2" @click="handleNav('s2')">
              <a href="#s2">{{ $t("nav.about") }}</a>
              <div></div>
            </li>
            <li :class="S3" @click="handleNav('s3')">
              <a href="#s3">{{ $t("nav.business") }}</a>
              <div></div>
            </li>
            <li :class="S4" @click="handleNav('s4')">
              <a href="#s4">{{ $t("nav.features") }}</a>
              <div></div>
            </li>
            <li :class="S5" @click="handleNav('s5')">
              <a href="#s5">{{ $t("nav.service") }}</a>
              <div></div>
            </li>
            <li :class="S6" @click="handleNav('s6')">
              <a href="#s6">{{ $t("nav.contact") }}</a>
              <div></div>
            </li>
            <li>
              <router-link to="/projects">{{ $t("nav.project") }}</router-link>
            </li>
            <li>
              <span
                :class="language ? 'languageActive' : ''"
                @click="handleChangeLanguage('zh-CN')"
                >中文</span
              >
              /
              <span
                :class="language ? '' : 'languageActive'"
                @click="handleChangeLanguage('en-US')"
                >EN</span
              >
            </li>
          </ul>
          <img
            class="nav_menu"
            src="@/assets/mobile/icon_menu.png"
            alt=""
            @click="handleShowMenu"
          />
          <button class="building" @click="handleBuild">Building Better Worlds</button>
        </div>
      </section>
      <div :class="`header_container ${language ? '' : 'en'}`">
        <h3>{{ t("slogan.title") }}</h3>
        <span>{{ t("slogan.subTitle") }}</span>
        <p :class="enClassName">{{ t("slogan.desc") }}</p>
        <span>{{ t("slogan.subName") }}</span>
        <p>{{ t("slogan.content") }}</p>
        <div class="fixed_form">
          <input :placeholder="t('formSmall.input')" />
          <button @click.stop="handleSendMail">
            {{ t("formSmall.button") }}
            <img src="@/assets/icon_arrow.svg" alt="" />
          </button>
        </div>
      </div>
    </section>
    <section :class="`explain ${language ? '' : 'en'}`">
      <h3>{{ t("explain.title") }}</h3>
      <p>{{ t("explain.desc") }}</p>
    </section>
    <section class="about" id="s2">
      <section :class="`about_container ${language ? '' : 'en'}`">
        <div class="about_part_us">
          <div></div>
          <div>
            <h3>{{ t("about.title") }}</h3>
            <p>{{ t("about.desc") }}</p>
            <p v-if="language">{{ t("about.content") }}</p>
          </div>
        </div>
        <div class="about_en_us" v-if="!language">{{ t("about.content") }}</div>
        <div class="about_part_object">
          <p>{{ t("object.title") }}</p>
          <ul>
            <li v-for="(item, idx) in data.icons" :key="idx">
              <img :src="item" alt="" />
              {{ t(`object.${idx}`) }}
            </li>
          </ul>
        </div>
      </section>
    </section>
    <section :class="`business ${language ? '' : 'en'}`" id="s3">
      <div class="business_title">
        {{ $t("servicesTitle") }}
      </div>
      <ul>
        <li v-for="(item, idx) in data.businessIcons" :key="idx">
          <img :src="item" alt="" />
          <span>{{ t(`business.${idx}.title`) }}</span>
          <p>{{ t(`business.${idx}.desc`) }}</p>
        </li>
      </ul>
    </section>
    <section :class="`features ${language ? '' : 'en'}`" id="s4">
      <h3>{{ t("featuresTitle") }}</h3>
      <div
        class="features_item"
        v-for="(item, idx) in data.featuresIcons"
        :key="idx"
      >
        <div class="features_item_img">
          <img :src="item" alt="" />
          <span>{{ t(`features.${idx}.title`) }}</span>
        </div>
        <div class="features_item_content">
          <h3>{{ t(`features.${idx}.title`) }}</h3>
          <p>{{ t(`features.${idx}.desc`) }}</p>
        </div>
      </div>
    </section>
    <section :class="`logic ${language ? '' : 'en'}`" id="s5">
      <h3>{{ t("businessTitle") }}</h3>
      <div :class="`logic_img ${language ? 'logic_cn' : 'logic_en'}`"></div>
    </section>
    <section class="encryption">
      <div :class="`encryption_container ${language ? '' : 'en'}`">
        <h3>{{ t("encryption.title") }}</h3>
        <p>{{ t("encryption.0") }}</p>
        <p>{{ t("encryption.1") }}</p>
        <p>{{ t("encryption.2") }}</p>
      </div>
    </section>
    <section class="contact" id="s6">
      <div :class="`contact_container ${language ? '' : 'en'}`">
        <img src="@/assets/logo.svg" alt="" />
        <div class="fixed_form">
          <input :placeholder="placeholder" />
          <button @click.stop="handleSendMail">
            {{ t("form.button") }} <img src="@/assets/icon_arrow.svg" alt="" />
          </button>
        </div>
      </div>
    </section>
  </section>
  <section
    class="menu"
    :class="{ shake: data.visiable, hide: !data.visiable, en: !language }"
    @click="handleShowMenu"
    @touchmove.prevent
    @mousewheel.prevent
  >
    <div @click.stop>
      <img
        src="@/assets/mobile/icon_close.png"
        class="menu_close"
        @click="handleShowMenu"
        alt=""
      />
      <ul>
        <li :class="S1" @click="handleNav('s1')">
          <a href="#s1"
            >{{ $t("nav.index") }}
            <div></div
          ></a>
        </li>
        <li :class="S2" @click="handleNav('s2')">
          <a href="#s2"
            >{{ $t("nav.about") }}
            <div></div
          ></a>
        </li>
        <li :class="S3" @click="handleNav('s3')">
          <a href="#s3"
            >{{ $t("nav.business") }}
            <div></div
          ></a>
        </li>
        <li :class="S4" @click="handleNav('s4')">
          <a href="#s4"
            >{{ $t("nav.features") }}
            <div></div
          ></a>
        </li>
        <li :class="S5" @click="handleNav('s5')">
          <a href="#s5"
            >{{ $t("nav.service") }}
            <div></div
          ></a>
        </li>
        <li :class="S6" @click="handleNav('s6')">
          <a href="#s6"
            >{{ $t("nav.contact") }}
            <div></div
          ></a>
        </li>
        <li>
          <router-link to="/projects">{{ $t("nav.project") }}</router-link>
        </li>
        <li>
          <span
            :class="language ? 'languageActive' : ''"
            @click="handleChangeLanguage('zh-CN')"
            >中文</span
          >
          /
          <span
            :class="language ? '' : 'languageActive'"
            @click="handleChangeLanguage('en-US')"
            >EN</span
          >
        </li>
      </ul>
      <button class="building" @click="handleBuild">
        Building Better Worlds
      </button>
    </div>
  </section>
  <section
    class="fixed_input"
    v-show="data.isMail"
    :class="{ open: data.isFixedForm }"
  >
    <div class="fixed_input_container">
      <img
        src="@/assets/mail.svg"
        class="mail_pc"
        @click="handleMail"
        alt=""
        v-show="!data.isFixedForm"
      />
      <img
        src="@/assets/mail.svg"
        class="mail_m"
        @click="handleMobileMail"
        alt=""
        v-show="!data.isFixedForm"
      />
      <div
        class="fixed_form"
        v-show="data.isFixedForm"
        :class="{ form_show: data.isFixedForm }"
      >
        <input
          :placeholder="placeholder"
          id="focusinput"
          autofocus
          ref="input"
          @blur="handleBlur"
        />
        <button @click.stop="handleSendMail">
          {{ t("form.button") }} <img src="@/assets/icon_arrow.svg" alt="" />
        </button>
      </div>
    </div>
  </section>
  <div class="fixed_input2" v-show="data.isV">
    <div class="fixed_form">
      <input
        :placeholder="placeholder"
        id="focusinput"
        ref="input2"
        @blur="handleBlur"
      />
      <button @click.stop="handleSendMail">
        {{ t("form.button") }} <img src="@/assets/icon_arrow.svg" alt="" />
      </button>
    </div>
  </div>
</template>
<script>
import { ref, reactive, computed, onMounted, onUnmounted, nextTick } from "vue";
import { useRoute } from 'vue-router'
import { useI18n } from "vue-i18n";
export default {
  name: "App",
  setup() {
    const route = useRoute()
    // 基础相应数据集
    const data = reactive({
      language: window.localStorage.getItem("locale") || "en-US",
      className: "",
      scrollPosition: "s1",
      visiable: false,
      icons: [
        require("@/assets/icon_about_01.png"),
        require("@/assets/icon_about_02.png"),
        require("@/assets/icon_about_03.png"),
        require("@/assets/icon_about_04.png"),
        require("@/assets/icon_about_05.png"),
        require("@/assets/icon_about_06.png"),
        require("@/assets/icon_about_07.png"),
        require("@/assets/icon_about_08.png"),
        require("@/assets/icon_about_09.png"),
        require("@/assets/icon_about_10.png"),
        require("@/assets/icon_about_11.png"),
        require("@/assets/icon_about_12.png"),
      ],
      businessIcons: [
        require("@/assets/icon_service_1.jpg"),
        require("@/assets/icon_service_2.jpg"),
        require("@/assets/icon_service_3.jpg"),
        require("@/assets/icon_service_4.jpg"),
      ],
      featuresIcons: [
        require("@/assets/icon_features_1.png"),
        require("@/assets/icon_features_2.png"),
        require("@/assets/icon_features_3.png"),
      ],
      isFixedForm: false,
      isV: false,
      isMail: true,
    });
    // i18n 对应方法
    const { t, locale } = useI18n({ useScope: "global" });
    // 切换语言
    const handleChangeLanguage = (language) => {
      locale.value = language;
      data.language = language;
      window.localStorage.setItem("locale", language)
    };
    // 获取语言状态
    const language = computed(() => data.language === "zh-CN");
    // 根据不同语言变更css样式
    const enClassName = computed(() => {
      return language.value ? "" : "en";
    });
    // 点击导航切换导航选中状态
    const handleNav = (val) => {
      data.scrollPosition = val;
      data.visiable = false;
    };
    // 点击会议网站
    const handleBuild = () => {
      window.open('http://www.buildingbetterworlds.io/')
    }
    // 监测滚动条滚动距离并变更导航状态
    /* eslint-disable */
    const handleScroll = () => {
      // data.isFixedForm = false
      let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
      
      let winWidth = document.body.clientWidth
      // 设置导航背景颜色
      if (winWidth <= 768) {
        if (scrollTop > 0) data.className = 'nav_bg'
        else data.className = ''
      } else {
        if (scrollTop > 30) data.className = 'nav_bg'
        else data.className = ''
      } 
      // 设置导航选中状态
      if (winWidth <= 768 && language.value) {
        if (scrollTop < 1000) {
          data.scrollPosition = 's1'
          data.isMail = true
        } else if (scrollTop > 1000 && scrollTop < 2000) {
          data.scrollPosition = 's2'
          data.isMail = true
        } else if (scrollTop > 2000 && scrollTop < 3500) {
          data.scrollPosition = 's3'
          data.isMail = true
        } else if (scrollTop > 3500 && scrollTop < 4800) {
          data.scrollPosition = 's4'
          data.isMail = true
        } else if(scrollTop > 4800 &&  scrollTop < 5800) {
          data.scrollPosition = 's5'
          data.isMail = true
        } else if (scrollTop > 5800) {
          data.scrollPosition = 's6'
          data.isMail = false
        }
      } else if (winWidth <= 768 && !language.value) {
        if (scrollTop < 1000) {
          data.scrollPosition = 's1'
          data.isMail = true
        } else if (scrollTop > 1000 && scrollTop < 2600) {
          data.scrollPosition = 's2'
          data.isMail = true
        } else if (scrollTop > 2600 && scrollTop < 4300) {
          data.scrollPosition = 's3'
          data.isMail = true
        } else if (scrollTop > 4300 && scrollTop < 6000) {
          data.scrollPosition = 's4'
          data.isMail = true
        } else if(scrollTop > 6000 &&  scrollTop < 7000) {
          data.scrollPosition = 's5'
          data.isMail = true
        } else if (scrollTop > 7000) {
          data.scrollPosition = 's6'
          data.isMail = false
        }
      } else {
        if (scrollTop < 300) {
          data.scrollPosition = 's1'
        } else if (scrollTop > 800 && scrollTop < 1500) {
          data.scrollPosition = 's2'
        } else if (scrollTop > 1700 && scrollTop < 2600) {
          data.scrollPosition = 's3'
        } else if (scrollTop > 2700 && scrollTop < 3800) {
          data.scrollPosition = 's4'
        } else if(scrollTop > 3800 &&  scrollTop < 4800) {
          data.scrollPosition = 's5'
        } else if (scrollTop > 4800) {
          data.scrollPosition = 's6'
        }
      }
    }
    const S1 = computed(() => data.scrollPosition === "s1" ? 'active' : '');
    const S2 = computed(() => data.scrollPosition === "s2" ? 'active' : '');
    const S3 = computed(() => data.scrollPosition === "s3" ? 'active' : '');
    const S4 = computed(() => data.scrollPosition === "s4" ? 'active' : '');
    const S5 = computed(() => data.scrollPosition === "s5" ? 'active' : '');
    const S6 = computed(() => data.scrollPosition === "s6" ? 'active' : '');
    const placeholder = computed(() => {
      let winWidth = document.body.clientWidth
      if (!language.value && 1000 > winWidth) {
        return 'your email address'
      } else {
        return t("form.input")
      }
    })
    
    const input = ref(null)
    const handleMail = () => {
      data.isFixedForm = true
      nextTick(() => {
        input.value.focus()
        input.value.scrollIntoView(true)
      })
    }
    const handleBlur = () => {
      // 失去焦点时取消显示
      setTimeout(() => {
      data.isFixedForm = false
      data.isV = false
      }, 100)

    }

    const handleShowMenu = () => {
      data.visiable = !data.visiable
    }

    const touchScroll = () => {
      nextTick(() => {
        if (input) input.value.blur()
        if (input) input2.value.blur()
      })
    }

    const main = ref(null)

    // 生命周期相关内容
    onMounted(() => {
      nextTick(() => {
        var anchor = document.getElementById(route.query.anchor);//获取元素
        if(anchor) {
          anchor.scrollIntoView();
        }
      })
      window.addEventListener("scroll", handleScroll);
      if (main && main.value) main.value.addEventListener("touchend", touchScroll);
    });
    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll)
      if (main && main.value) main.value.removeEventListener("touchend", touchScroll);
    });
    const input2 = ref(null)
    const handleMobileMail = () => {
      data.isV = true
      nextTick(() => {
        input2.value.focus()
      })
    }

    const handleSendMail = () => {
      // console.log('Send Mail')
    }
    return {
      handleChangeLanguage,
      t,
      language,
      data,
      enClassName,
      S1,S2,S3,S4,S5,S6,
      handleNav,
      handleBuild,
      handleShowMenu,
      placeholder,
      input,
      handleBlur,
      handleMail,
      main,
      handleMobileMail,
      input2,
      handleSendMail
    };
  },
};
</script>

<style lang="less" scoped>
.activeMenu {
  height: 100vh;
  overflow: hidden;
}
.nav {
  width: 100%;
  height: 70px;
  color: #fff;
  position: absolute;
  top: 30px;
  .nav_logo {
    width: 160px;
    height: 40px;
  }
  &_container {
    width: 1024px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    position: relative;
    &.en {
      ul > li {
        a {
          font-family: Akrobat-Light;
          font-size: 16px;
          letter-spacing: 0;
          text-align: center;
          line-height: 16px;
          font-weight: 400;
        }
        &.active {
          a {
            font-family: Akrobat-Black;
          }
        }
      }
    }
  }
  ul {
    flex: 1;
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin-left: 200px;
    font-size: 14px;
    a {
      color: #fff;
      text-decoration: none;
      font-weight: 200;
    }
    span {
      cursor: pointer;
    }
    div {
      width: 28px;
      height: 4px;
      background: #84F4F9;
      border-radius: 2px;
      margin: 0 auto;
      margin-top: 8px;
      opacity: 0;
    }
    li.active {
      a {
        color: #84F4F9;
      }
      div {
        opacity: 1
      }
    }
  }
  &_menu {
    display: none;
  }
}
.nav_bg {
  background: rgba(15,3,52,0.70);
  box-shadow: 0 2px 8px 0 rgba(0,0,0,0.70);
  margin-top: 0;
  position: fixed;
  top: 0;
}
.header {
  width: 100%;
  background: url("./../assets/head_bg.jpg") no-repeat top center;
  background-size: auto 100%;
  height: 570px;
  padding-top: 70px;
  &_container {
    width: 1024px;
    margin: 0 auto;
    h3 {
      color: #60AEFF;
      font-size: 36px;
      margin-left: 90px;
      margin-top: 100px;
    }
    span {
      color: #84F4F9;
      font-size: 24px;
      margin-top: 30px;
      font-weight: 200;
      display: block;
      font-weight: lighter;
    }
    p {
      width: 492px;
      font-size: 16px;
      color: #F5F5F4;
      line-height: 24px;
      text-shadow: 0 2px 4px rgba(0,0,0,0.50);
      font-weight: 400;
      margin-top: 20px;
    }
    .fixed_form {
      margin-top: 40px;
      margin-left: 88px;
      width: 316px;
    }
    &.en {
      h3 {
        font-family: Akrobat-Black;
        font-style: oblique;
        margin-top: 80px;
      }
      span {
        font-family: Akrobat-Light;
        font-size: 28px;
        color: #84F4F9;
        letter-spacing: 0;
        line-height: 28px;
        text-shadow: 0 2px 4px rgba(0,0,0,0.50);
        font-weight: 300;
        margin-top: 20px;
      }
      p {
        font-family: Akrobat-Bold;
        font-size: 21px;
        color: #F5F5F4;
        letter-spacing: 0;
        line-height: 28px;
        text-shadow: 0 2px 4px rgba(0,0,0,0.50);
        font-weight: 900;
        width: 492px;
        margin-top: 10px;
      }
      .fixed_form {
        margin-top: 30px;
      }
    }
  }
}
.explain {
  background: url('./../assets/explain_bg.jpg') no-repeat center center;
  height: 380px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h3 {
    font-size: 24px;
    color: #FFFFFF;
    letter-spacing: 0;
    text-align: center;
    line-height: 36px;
    font-weight: 200;
    margin: 0 auto;
    width: 460px;
    font-weight: lighter;
  }
  p {
    font-size: 16px;
    color: #F5F5F4;
    letter-spacing: 0;
    line-height: 24px;
    font-weight: 400;
    margin: 0 auto;
    margin-top: 30px;
    width: 844px;
  }
  &.en {
    h3 {
      font-family: Akrobat-Light;
      font-size: 28px;
      color: #FFFFFF;
      letter-spacing: 0;
      text-align: center;
      line-height: 42px;
      font-weight: 300;
    }
    p {
      font-family: Akrobat-Bold;
      font-size: 21px;
      color: #F5F5F4;
      letter-spacing: 0;
      line-height: 28px;
      font-weight: 900;
    }
  }
}
.about {
  background: url('./../assets/about_bg.jpg') no-repeat center center;
  height: 980px;
  &_container {
    width: 1020px;
    margin: 0 auto;
    height: 100%;
    .about_part_us {
      display: flex;
      height: 420px;
      padding-top: 83px;
      & > div {
        flex: 1;
        height: 90%;
        &:first-child {
          background: url('./../assets/about_logo.png') no-repeat center center;
          background-size: 192px 240px;
        }
        h3 {
          font-size: 32px;
          color: #FFFFFF;
          letter-spacing: 0;
          line-height: 42px;
          font-weight: 600;
          margin: 0;
          padding-bottom: 10px;
        }
        p {
          font-size: 16px;
          color: #F5F5F4;
          letter-spacing: 0;
          line-height: 24px;
          font-weight: 400;
          margin-top: 20px;
        }
      }
    }
    .about_en_us {
      font-family: Akrobat-Bold;
      font-size: 21px;
      color: #F5F5F4;
      letter-spacing: 0;
      line-height: 28px;
      font-weight: 900;
      margin-top: 30px;
    }
    .about_part_object {
      p {
        width: 580px;
        font-size: 24px;
        color: #FFFFFF;
        letter-spacing: 0;
        text-align: center;
        line-height: 36px;
        font-weight: 200;
        margin: 0 auto;
      }
      ul {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        margin-top: 70px;
        li {
          flex: 0 0 245px;
          color: #fff;
          display: flex;
          align-items: center;
          margin-top: 20px;
          font-size: 18px;
          color: #FFFFFF;
          letter-spacing: 0;
          line-height: 18px;
          font-weight: 600;
          img {
            width: 52px;
            height: 52px;
            margin-right: 10px;
          }
        }
      }
    }
    &.en {
      .about_part_us {
        height: 290px;
        padding-top: 55px;
         & > div {
           &:first-child {
             height: 100%;
             background-position: center 90%;
           }
         }
        h3 {
          font-family: Akrobat-Black;
          font-size: 36px;
          color: #FFFFFF;
          letter-spacing: 0;
          line-height: 42px;
          font-weight: 900;
        }
        p {
          font-family: Akrobat-Bold;
          font-size: 21px;
          color: #F5F5F4;
          letter-spacing: 0;
          line-height: 28px;
          font-weight: 900;
        }
      }
      .about_part_object {
        p {
          width: 845px;
          font-family: Akrobat-Light;
          font-size: 28px;
          color: #FFFFFF;
          letter-spacing: 0;
          text-align: center;
          line-height: 42px;
          font-weight: 300;
          margin-top: 30px;
        }
        ul {
          margin-top: 40px;
          li {
            font-family: Akrobat-Bold;
            font-size: 21px;
            color: #FFFFFF;
            letter-spacing: 0;
            line-height: 21px;
            font-weight: 700;
          }
        }
      }
    }
  }
}
.features {
  width: 1020px;
  margin: 0 auto;
  padding-bottom: 130px;
  & > h3 {
    font-size: 32px;
    color: #655E72;
    letter-spacing: 0;
    text-align: center;
    line-height: 42px;
    font-weight: 600;
    padding-top: 90px;
    margin-bottom: 30px;
  }
  &_item {
    display: flex;
    width: 100%;
    height: 256px;
    background: #fff;
    margin-bottom: 20px;
    &_img {
      background: url('./../assets/icon_features_bg.jpg') no-repeat;
      background-size: 100% 100%;
      flex: 0 0 228px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 128px;
        height: 128px;
      }
      span {
        display: none;
      }
    }
    &_content {
      flex: 1;
      padding: 44px 36px;
      h3 {
        font-size: 24px;
        color: #60AEFF;
        letter-spacing: 0;
        line-height: 28px;
        font-weight: 600;
      }
      p {
        font-size: 16px;
        color: #655E72;
        letter-spacing: 0;
        line-height: 24px;
        font-weight: 400;
        margin-top: 20px;
      }
    }
  }
  &.en {
    h3 {
      font-family: Akrobat-Black;
    }
    .features_item {
      height: 330px;
      &_content {
      h3 {
        font-family: Akrobat-Black;
        font-size: 28px;
        color: #60AEFF;
        letter-spacing: 0;
        line-height: 28px;
        font-weight: 900;
      }
      p {
        font-family: Akrobat-Bold;
        font-size: 21px;
        color: #655E72;
        letter-spacing: 0;
        line-height: 28px;
        font-weight: 900;
      }
    }
    }
  }
}
.business {
  width: 100%;
  height: 1080px;
  background: #0F0334;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  &_title {
    font-size: 32px;
    color: #655E72;
    letter-spacing: 0;
    text-align: center;
    line-height: 42px;
    font-weight: 600;
    margin: 90px 0 30px 0;
  }
  ul {
    width: 1020px;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    justify-content: space-between;
    li {
      flex: 0 0 492px;
      height: 438px;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 168px;
        height: 168px;
      }
      span {
        font-size: 24px;
        color: #60AEFF;
        letter-spacing: 0;
        text-align: center;
        line-height: 28px;
        font-weight: 600;
        display: block;
        margin: 20px 0;
      }
      p {
        font-size: 16px;
        color: #F5F5F4;
        letter-spacing: 0;
        line-height: 24px;
        font-weight: 400;
      }
    }
  }
  &.en {
    .business_title {
      font-family: Akrobat-Black;
      font-size: 36px;
      color: #655E72;
      letter-spacing: 0;
      text-align: center;
      line-height: 42px;
      font-weight: 900;
      margin-top: 40px;
    }
    ul > li {
      height: 354px;
      margin-bottom: 50px;
      span {
        font-family: Akrobat-Black;
        font-size: 28px;
        color: #60AEFF;
        letter-spacing: 0;
        text-align: center;
        line-height: 28px;
        font-weight: 900;
      }
      p {
        font-family: Akrobat-Bold;
        font-size: 21px;
        color: #F5F5F4;
        letter-spacing: 0;
        line-height: 28px;
        font-weight: 900;
      }
    }
  }
}
.logic {
  width: 100%;
  height: 800px;
  background: url('./../assets/logic_bg.jpg') no-repeat center center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h3 {
    font-size: 32px;
    color: #655E72;
    letter-spacing: 0;
    text-align: center;
    line-height: 42px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  &_img {
    width: 1020px;
    height: 572px;
    &.logic_en {
      background: url('./../assets/logic_en.png') no-repeat;
      background-size: 100% 100%;
    }
    &.logic_cn {
      background: url('./../assets/logic_cn.png') no-repeat;
      background-size: 100% 100%;
    }
  }
  &.en {
    h3 {
      font-family: Akrobat-Black;
      font-size: 36px;
      color: #655E72;
      letter-spacing: 0;
      text-align: center;
      line-height: 42px;
      font-weight: 900;
    }
  }
}
.encryption {
  width: 100%;
  height: 580px;
  background: url('./../assets/encryption_bg.jpg') no-repeat center center;
  display: flex;
  justify-content: center;
  align-items: center;
  &_container {
    width: 1024px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h3 {
      width: 449px;
      font-size: 32px;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 42px;
      padding-bottom: 20px;
    }
    p {
      width: 492px;
      font-size: 16px;
      font-weight: 400;
      color: #F5F5F4;
      line-height: 24px;
      margin-top: 20px;
    }
    &.en {
      h3 {
        font-family: Akrobat-Black;
        font-size: 36px;
        color: #FFFFFF;
        letter-spacing: 0;
        line-height: 42px;
        font-weight: 900;
      }
      p {
        font-family: Akrobat-Bold;
        font-size: 21px;
        color: #F5F5F4;
        letter-spacing: 0;
        line-height: 28px;
        font-weight: 900;
      }
    }
  }
}
.contact {
  width: 100%;
  height: 540px;
  background: #0f0233 url("./../assets/contact_bg.jpg") no-repeat bottom center;
  &_container {
    width: 1024px;
    padding-top: 140px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > img {
      width: 320px;
      height: 80px;
    }
  }
}
.fixed_form {
  width: 400px;
  height: 50px;
  background: #fff;
  border-radius: 25px;
  display: flex;
  align-items: center;
  padding-left: 24px;
  input {
    border: 0;
    flex: 1;
    &:focus {
      outline: none;
      border: 0px solid #fff;
    }
    &::-webkit-input-placeholder {
      color: #BAB8B1;
      font-size: 16px;
      letter-spacing: 0;
      // line-height: normal;
      font-weight: 400;
    }
    &::-moz-placeholder {
      color: #bab8b1;
      font-size: 16px;
      letter-spacing: 0;
      // line-height: normal;
      font-weight: 400;
    }
    &:-moz-placeholder {
      color: #bab8b1;
      font-size: 16px;
      letter-spacing: 0;
      // line-height: normal;
      font-weight: 400;
    }
    &:-ms-input-placeholder {
      color: #bab8b1;
      font-size: 16px;
      letter-spacing: 0;
      // line-height: normal;
      font-weight: 400;
    }
  }

  button {
    border: 0;
    width: 90px;
    height: 38px;
    background: #f36b32;
    border-radius: 19px;
    display: flex;
    align-items: center;
    margin-right: 6px;
    justify-content: flex-end;
    cursor: pointer;

    font-size: 16px;
    color: #F5F5F4;
    letter-spacing: 0;
    line-height: 24px;
    font-weight: 600;
    img {
      margin-left: 8px;
      margin-right: 3px;
      width: 22px;
      height: 22px;
    }
  }
}
.en {
  .fixed_form {
    input {
      &::-webkit-input-placeholder {
        font-family: Akrobat-Bold;
      }
      &::-moz-placeholder {
        font-family: Akrobat-Bold;
      }
      &:-moz-placeholder {
        font-family: Akrobat-Bold;
      }
      &:-ms-input-placeholder {
        font-family: Akrobat-Bold;
      }
    }
    button {
      font-family: Akrobat-Bold;
    }
  }
}
.languageActive {
  color: #84F4F9;
  font-size: 14px;
  color: #84F4F9;
  letter-spacing: 0;
  text-align: center;
  line-height: 14px;
  font-weight: bold;
}
.menu {
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.50);
  position: fixed;
  top: 0;
  right: 0;
  visibility:hidden;
  opacity:0;
  transform:translateY(0px);
  transition:.3s;
  z-index: 2;
  & > div {
    width: 64vw;
    height: 100vh;
    background: #1D0568;
    position: absolute;
    top: 0;
    right: 0;
    ul {
      list-style: none;
      color: #fff;
      margin-top: 24vw;
      li {
        margin-bottom: 5vw;
        a {
          color: #F5F5F4;
          text-decoration: none;
          display: inline-block;
          font-size: 3.73vw;
          & > div {
            height: 1.3vw;
            border-radius: .65vw;
            background: none;
            width: 100%;
            // display: inline-block;
          }
        }
        span {
          font-size: 3.73vw;
        }
        &.active {
          a {
            color: #84F4F9;
            & > div {
              background: #84F4F9;
            }
          }
        }
      }
      
    }
  }
  &_close {
    width: 4.2vw;
    margin-left: 55.7vw;
    margin-top: 3.2vw;
  }
  &.en {
    
    ul li {
      a {
        font-size: 4.8vw;
        font-family: Akrobat-Bold;
        & > div {
          width: 60%;
          margin: 0 auto;
        }
      }
      &.active {
        a {
          color: #84F4F9;
          font-family: Akrobat-Black;
          & > div {
            background: #84F4F9;
          }
        }
      }
    }
  }
  .building {
    position: relative;
    right: auto;
    top: 25vw;
    left: 10vw;
  }
}
.shake {
  visibility:visible;
  opacity:1;
  transform:translateY(0);
}
.fixed_input {
  position: fixed;
  right: 10px;
  bottom: 50px;
  height: 80px;
  min-width: 80px;
  background: #60AEFF;
  box-shadow: 0px 8px 8px 0px rgba(15, 3, 52, 0.3);
  border-radius: 40px;

  &_container {
    width: 100%;
    height: 100%;
    border-radius: 40px;
    box-shadow: 0px 0px 20px rgba(131, 244, 249, 0.8) inset;
    display: flex;
    align-items: center;
    justify-content: center;
    & > img {
      width: 40px;
      height: 28px;
      cursor: pointer;
      &.mail_pc {
        display: block;
      }
      &.mail_m {
        display: none;
      }
    }
    .fixed_form {
      opacity: 0;
      margin: 0 20px;
    }
  }
}
@keyframes aslide {
   0% {
    width: 80px;
   }
   100% {
    width: 464px;
   }
}
.open {
  animation: aslide .5s;
  animation-fill-mode: forwards;
}
@keyframes aslide1 {
   0% {
    opacity: 0;
   }
   100% {
    opacity: 1;
   }
}
.form_show {
  animation: aslide1 .1s;
  animation-fill-mode: forwards;
  animation-delay: .25s;
}

.fixed_input2 {
  position: fixed;
  bottom: 5vw;
  width: 84vw;
  right: 8vw;
  z-index: 3;
  .fixed_form {
    width: 80vw;
    padding-left: 4vw;
  }
  input {
    width: 100%;
    border: 1px solid #000;
    margin: 0;
    padding: 0;
  }
}

.building {
  width: 144px;
  height: 24px;
  border: 1px solid #fff;
  border-radius: 12px;
  background: transparent;
  color: #fff;
  font-size: 12px;
  position: absolute;
  right: -170px;
  top: 16px;
}

@media (max-width: 768px) {
  html,body {
    overflow-x: hidden;
  }
  .nav {
    height: 10.6vw;
    background: #0F0334;
    top: 0;
    .nav_logo {
      width: 26.6vw;
    }
    ul {
      display: none;
    }
    &_container {
      justify-content: space-between;
      width: 92%;
      padding: 0 4%;
    }
    &_menu {
      display: block;
      width: 4.8vw;
    }
  }
  .header {
    background: #0F0334 url('./../assets/mobile/header_bg.jpg') no-repeat bottom center;
    background-size: 100% auto;
    height: auto;
    padding-bottom: 105vw;
    &_container {
      width: 84%!important;
      display: flex;
      flex-direction: column;
      align-items: center;
      h3, span, p {
        margin: 0;
        width: 100%;
        text-align: center;
      }
      h3 {
        padding-top: 10vw;
        padding-bottom: 5vw;
        font-size: 6.4vw;
        color: #60AEFF;
        letter-spacing: 0;
        text-align: center;
        font-weight: 600;
      }
      span {
        margin-top: 10vw;
        font-size: 4.8vw;
        color: #84F4F9;
        letter-spacing: 0;
        text-align: center;
        text-shadow: 0 2px 4px rgba(0,0,0,0.50);
        font-weight: 200;
      }
      p {
        margin-top: 5vw;
        font-size: 3.7vw;
        color: #F5F5F4;
        letter-spacing: 0;
        text-align: center;
        text-shadow: 0 2px 4px rgba(0,0,0,0.50);
        font-weight: 400;
        line-height: normal;
      }
      p:last-child {
        text-align: left;
      }
      .fixed_form {
        display: none;
      }
      &.en {
        h3 {
          font-size: 9.6vw;
          margin: 0;
        }
        span {
          font-size: 7.46vw;
        }
        p {
          width: 84vw;
          font-size: 4.8vw;
          text-align: left;
          line-height: normal;
        }
        .header_container_sub {
          width: 84vw;
        }
      }
    }
  }
  .explain {
    width: 100%;
    height: 120vw;
    background: url('./../assets/mobile/explain_bg.jpg') no-repeat center center;
    background-size: 100% auto;
    h3 {
      width: 84vw;
      font-size: 4.8vw;
      color: #FFFFFF;
      letter-spacing: 0;
      line-height: normal;
      font-weight: 200;
    }
    p {
      width: 84vw;
      font-size: 3.72vw;
      color: #F5F5F4;
      letter-spacing: 0;
      text-align: justify;
      line-height: normal;
      font-weight: 400;
    }
    &.en {
      h3 {
        font-size: 7.46vw;
        color: #FFFFFF;
        letter-spacing: 0;
        line-height: normal;
        font-weight: 300;
      }
      p {
        font-size: 4.8vw;
        line-height: normal;
      }
    }
  }
  .about {
    background: #60AEFF url('./../assets/mobile/about_bg.jpg') no-repeat top center;
    background-size: 100% auto;
    height: auto;
    &_container {
      width: 100%;
      padding-bottom: 21.6vw;
      background: url('./../assets/mobile/about_footer_bg.jpg') no-repeat center bottom;
      background-size: 100% auto;
      .about_part_us {
        flex-direction: column;
        height: auto;
        padding-top: 7vw;
        width: 84vw;
        margin: 0 auto;
        & > div {
          &:first-child {
            flex: 0 0 60vw;
            background-size: 40.04vw 50vw;
          }
          h3 {
            font-size: 6.4vw;
            color: #FFFFFF;
            letter-spacing: 0;
            line-height: normal;
            font-weight: 600;
          }
          p {
            font-size: 3.73vw;
            color: #F5F5F4;
            letter-spacing: 0;
            text-align: justify;
            line-height: normal;
            font-weight: 400;
          }
        }
      }
      .about_part_object {
        width: 84vw;
        margin: 0 auto;
        p {
          width: 100%;
          font-size: 4.8vw;
          color: #FFFFFF;
          letter-spacing: 0;
          line-height: normal;
          font-weight: 200;
          margin-top: 8vw;
        }
        & > ul {
          padding: 0;
          margin-top: 8vw;
          li {
            flex: 0 0 42vw;
            font-size: 3.73vw;
            img {
              width: 6.93vw;
              height: 6.93vw;
            }
          }
        }
      }
      &.en {
        .about_part_us {
          height: auto;
          padding-top: 7vw;
          & > div {
            &:first-child {
              background-position: center center;
            }
            h3 {
              font-size: 9.6vw;
            }
            p {
              font-size: 4.8vw;
              line-height: normal;
            }
          }
        }
        .about_en_us {
          width: 84vw;
          font-size: 4.8vw;
          line-height: normal;
          margin: 0 auto;
          margin-top: 3vw;
        }
        .about_part_object {
          p {
            width: 84vw;
            font-size: 7.5vw;
            text-align: left;
            line-height: normal;
          }
          ul > li {
            font-size: 4.8vw;
          }
        }
      }
    }
  }
  .features {
    width: 84%;
    padding-bottom: 10vw;
    h3 {
      text-align: left;
      font-size: 6.4vw;
      color: #655E72;
      letter-spacing: 0;
      line-height: normal;
      font-weight: 600;
      padding-top: 9.6vw;
      margin-bottom: 8vw;
    }
    &_item {
      margin-bottom: 2.6vw;
      flex-direction: column;
      height: auto;
      margin-bottom: 5.3vw;
      &_img {
        flex: 0 0 29.3vw;
        justify-content: flex-start;
        img {
          width: 17vw;
          height: 17vw;
          margin-left: 6.1vw;
        }
        span {
          font-size: 4.8vw;
          color: #60AEFF;
          letter-spacing: 0;
          font-weight: 600;
          display: block;
          margin-left: 6.1vw;
          font-family: 'Akrobat-Black';
        }
      }
      &_content {
        padding: 3.6vw 5.3vw 8vw 5.3vw;
        h3 {
          display: none;
        }
        p {
          font-size: 3.73vw;
          color: #655E72;
          letter-spacing: 0;
          text-align: justify;
          line-height: normal;
          font-weight: 400;
          margin: 0;
        }
      }
    }
    &.en {
      h3 {
        font-size: 9.6vw;
      }
      .features_item {
        height: auto;
        &_img span {
          font-size: 7.46vw;
        }
        &_content p {
          font-size: 4.8vw;
          line-height: normal;
          text-align: left;
        }
      }
    }
  }
  .business {
    background: #0F0334;
    height: auto;
    &_title {
      font-size: 6.4vw;
      width: 84vw;
      text-align: left;
      margin: 9.6vw 0 5.3vw 0;
    }
    ul {
      width: 100%;
      padding: 0;
      flex-direction: column;
      li {
        flex: none;
        height: auto;
        padding-bottom: 10.6vw;
        img {
          width: 37.3vw;
          height: 37.3vw;
        }
        p {
          width: 84vw;
          font-size: 3.73vw;
          line-height: normal;
        }
        span {
          font-size: 4.8vw;
        }
      }
    }
    &.en {
      .business_title {
        text-align: left;
        font-size: 9.6vw;
      }
      ul > li {
        height: auto;
        span {
          font-size: 7.46vw;
        }
        p {
          font-size: 4.8vw;
          line-height: normal;
        }
      }
    }
  }
  .logic {
    background: url('./../assets/mobile/logic_bg.jpg') no-repeat center center;
    background-size: auto 100%;
    height: 200vw;
    h3 {
      width:84vw;
      font-size: 6.4vw;
      color: #655E72;
      letter-spacing: 0;
      line-height: normal;
      font-weight: 600;
      margin-bottom: 10vw;
      text-align: left;
    }
    .logic_img {
      width: 81vw;
      height: 139.7vw;
      margin-bottom: 20vw;
    }
    .logic_cn {
      background: url('./../assets/mobile/logic_cn.png') no-repeat center center;
      background-size: 100% 100%;
    }
    .logic_en {
      background: url('./../assets/mobile/logic_en.png') no-repeat center center;
      background-size: 100% 100%;
    }
    &.en {
      h3 {
        font-size: 9.6vw;
        text-align: left;
      }
    }
  }
  .encryption {
    width: 100vw;
    height: 200vw;
    background: url('./../assets/mobile/encryption_bg.jpg') no-repeat center center;
    background-size: 100% 100%;
    display: block;
    &_container {
      width: 84vw;
      height: auto;
      margin: 0 auto;
      h3 {
        width: 100%;
        font-size: 6.4vw;
        padding-top: 29.3vw;
        margin-bottom: 2.6vw;
      }
      p {
        width: 100%;
        font-size: 3.73vw;
        margin-top: 5.3vw;
        line-height: normal;
      }
      &.en {
        h3 {
          font-size: 9.6vw;
          padding-top: 10.6vw;
          padding-bottom: 0;
          margin-bottom: 0;
        }
        p {
          font-size: 4.8vw;
          line-height: normal;
          margin-top: 5.3vw;
        }
      }
    }
  }
  .contact {
    background: url('./../assets/mobile/contact_bg.jpg') no-repeat center center;
    background-size: 100% auto;
    height: 100vw;
    width: 100%;
    &_container {
      width: 100%;
      flex-direction: column;
      align-items: center;
      padding-top: 22vw;
      img {
        width: 42%;
      }
    }
  }
  .fixed_form {
    width: 84%;
    height: 13.3vw;
    margin-top: 12vw;
    align-items: center;
    border-radius: 7vw;
    input {
      height: 3.73vw;
      font-size: 3vw;
      line-height: normal;
      &::-webkit-input-placeholder {
        font-size: 3.73vw;
        transform: translate(0, 11%);
        -webkit-transform: translate(0, 11%);
      }
      &::-moz-placeholder {
        font-size: 3.73vw;
        transform: translate(0, 11%);
        -webkit-transform: translate(0, 11%);
      }
      &:-moz-placeholder {
        font-size: 3.73vw;
        transform: translate(0, 11%);
        -webkit-transform: translate(0, 11%);
      }
      &:-ms-input-placeholder {
        font-size: 3.73vw;
        transform: translate(0, 11%);
        -webkit-transform: translate(0, 11%);
      }
    }
    button {
      font-size: 3.73vw;
      width: 22vw;
      height: 10vw;
      border-radius: 5vw;
      padding: 0;
      line-height: normal;
      img {
        width: 7.46vw;
      }
    }
  }
  .fixed_input {
    min-width: 13.3vw;
    height: 13.3vw;
    bottom: 5.3vw;
    right: 8vw;
    z-index: 1;
    &_container {
      padding: 0;
      margin: 0;
      & > img {
        width: 6.93vw;
        height: 4.8vw;
        &.mail_pc {
          display: none;
        }
        &.mail_m {
          display: block;
        }
      }
      .fixed_form {
        width: 100%;
        margin: 0;
        padding: 0;
        input {
          margin-left: 3vw;
        }
      }
    }
  }
  @keyframes aslide {
    0% {
      width: 6.93vw;
    }
    100% {
      width: 84vw;
    }
  }
  .open {
    animation: aslide .5s;
    animation-fill-mode: forwards;
    width: 84vw;
  }
}
@media (max-width: 1030px) {

}
</style>
